<template>
	<div class="wrapper_main">
		<main class="content">
			<div class="content__header flex-md-col flex-lg-col">
				<div class="content__header-left">
					<h1 class="heading-page-h1">UI</h1>
				</div>
			</div>
			<div class="content__body">
                
				<div class="icon-list">
                    <div class="icon-list-item" v-for="icon in iconsPaths" :key="icon.key">
                        <img :src="icon.pathLong">
                        <span>{{ icon.key }}</span>
                    </div>
                </div>
			</div>

		</main>
	</div>	
</template>

<script>
import Base from '@/components/base';
import Icons from '@/components/icons';
export default {
	components: {
		...Base,
        ...Icons
	},
    data() {
        return {
            iconsPaths: []
        }
    },
    created() {
        const assets = require.context('@/assets/img/', true, /\.svg$/);
        this.iconsPaths = assets.keys().map(key => ({ pathLong: assets(key), key }))
        
    }
}
</script>

<style>
    .icon-list {
        columns: 3;
    }
    .icon-list-item {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding: 5px;
        margin: 5px;
    }
</style>